


























/**
 * 关于UploadImage组件的说明
 * @displayName UploadImage组件
 */

import { Vue, Component } from 'vue-property-decorator'
import { compressImg, imageRotate } from '@/components/common/compressImg'
import { is } from '@/utils/helpers'

@Component({})
export default class SearchImage extends Vue {
  searchUrl = ''
  searchImage = ''
  message: any = null

  async searchClick() {
    if (this.searchUrl !== '') {
      if (this.searchUrl.indexOf('http://') > -1 || this.searchUrl.indexOf('https://') > -1) {
        this.searchImage = this.searchUrl
      } else {
        this.$message({
          message: '请输入合法的图片地址',
          type: 'warning'
        })
        return
      }
    }
    if (this.searchImage !== '') {
      /**
       * 上传图片回调
       * @event change-img
       * @property {string}  图片资源
       */

      this.$emit('changeImg', this.searchImage)
    } else {
      this.message = this.$message({
        message: '请上传图片',
        type: 'warning'
      })
    }
  }
  async handleFile(e: HTMLInputEvent) {
    const $target = e.target || e.srcElement
    const file = $target.files ? $target.files[0] : null

    if (file) {
      const result = await compressImg(file)
      result.onload = data => {
        const res = (data.target || data.srcElement) as FileReader

        if (res) {
          this.rotateImage(res.result)
          this.searchUrl = ''
        }
      }
      if (!this.searchImage) {
        if (is.str(result.result)) {
          this.searchImage = result.result // 为了兼容ie10
          this.searchUrl = ''
        }
      }
      ;(this.$refs.inputFile as HTMLInputElement).value = ''
    }
  }
  uploadHeadImg() {
    if (this.message) this.message.close()
    this.$el.querySelector<HTMLInputElement>('.hiddenInput')?.click()
  }
  getImage(data: string) {
    this.searchImage = data
    this.searchClick()
  }

  rotateImage(file: any) {
    imageRotate(file, this.getImage)
  }
}
