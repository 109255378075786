import api from '@/api'
class Options {
  x = 0
  y = 140
  width = 0
  height = 0
}
class PersonDetect {
  private position: Options = new Options()
  async getPersonDetect(val: string, allowMultipleFace: boolean) {
    const errorMap: any = {
      'image is not qualified': '图片质量不合格',
      'no face detected': '未检测到人脸'
    }
    const img = await this.getImage(val)
    const { data, hideMessage } = (await api.v2.personsDetect(img)) as any
    if (data.code === 0) {
      if (data.data.length > 0) {
        if (!allowMultipleFace) {
          if (data.data.length > 1) {
            return { error: '请确保图片中有且仅有一张人脸' }
          }
        }
        this.position = data.data[0].position
        const image = (await this.drawImage(val)) as any
        return { image }
      }
    } else {
      hideMessage()
      const { detail, message } = data.error
      let errMsg
      if (detail && detail.length > 0) {
        errMsg = detail[0]
      } else {
        if (message && errorMap[message]) {
          errMsg = errorMap[message]
        } else {
          errMsg = message
        }
      }
      return { error: errMsg }
    }
  }
  getImage(val: any) {
    let CreateImg
    if (val.indexOf('http://') != -1 || val.indexOf('https://') != -1) {
      CreateImg = { imageUrl: val }
    } else {
      CreateImg = { imageData: val.split(',')[1] }
    }
    return CreateImg
  }
  async drawImage(val: any) {
    const img = new Image()
    img.src = val
    return new Promise(resolve => {
      img.onload = () => {
        const data = this.drawLine(img)
        resolve(data)
      }
    })
  }
  drawLine(img: any) {
    const { x, y, width, height } = this.position
    const canvas = document.createElement('canvas')
    const imgWidth = img.naturalWidth
    const imgHeight = img.naturalHeight
    canvas.width = imgWidth
    canvas.height = imgHeight
    const cxt = canvas.getContext('2d')
    if (cxt) {
      cxt.strokeStyle = '#14E6AA'
      const size = imgWidth > imgHeight ? imgHeight : imgWidth
      const lineWidth = Math.round((size * 4) / 326)
      cxt.lineWidth = lineWidth
      const saveX = lineWidth % 2 === 0 ? x : x + 0.5
      const saveY = lineWidth % 2 === 0 ? y : y + 0.5
      cxt.strokeRect(saveX, saveY, width, height)
      return canvas.toDataURL('image/png')
    } else {
      return null
    }
  }
}
export default new PersonDetect()
