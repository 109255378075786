















































































































































































































































import { Vue, Component } from 'vue-property-decorator'
import FeatureImg from '@/components/dashboard/FeatureImg/index.vue'
import CopyText from '@/components/common/CopyText.vue'
import { parseGender } from '@/utils'
import PersonDetail from '@/components/dashboard/PersonDetail/index.vue'
import pageTitle from '@/components/common/pageTitle.vue'
import UploadImage from '@/components/common/UploadImage/index.vue'
import detect from '@/utils/detectImage'
import SecurityText from '@/components/common/SecurityText/index.vue'

@Component({
  components: {
    FeatureImg,
    CopyText,
    PersonDetail,
    pageTitle,
    UploadImage,
    SecurityText
  }
})
export default class FaceSearch extends Vue {
  resultList: any[] = []
  offset = 0
  tagsMap = {
    vip: 'VIP',
    white: 'WHITE LIST',
    black: 'BLACK LIST'
  }
  personInfo: AnyObj = {}
  isResult = false
  searchImageData = ''
  rectImage: string | null = null
  imageError: string | null = null
  searchUrl = ''
  loading = false
  tabLoading = false
  listTitle = '???'
  personList = []
  index = 0
  moreLoading = false
  finished = true
  noData = require('@/assets/images/dashboard/noData.png')
  noMore = false
  isLoad = false
  showDetail = false
  showDetailDialog = false
  curPerson: AnyObj | null = null
  isSimilar = false

  mounted() {
    this.offset = 0
    this.isResult = false
    this.searchImageData = ''
    this.rectImage = ''
    this.searchUrl = ''
    this.resultList = []
    this.listTitle = '???'
    this.noMore = false
    this.tabLoading = true
    this.getResultList()
  }

  get user() {
    return this.$store.state.user || {}
  }

  get rootConfig() {
    return this.$store.state.config?.root
  }

  get root() {
    return this.rootConfig ? this.rootConfig.id : this.$store.state.user.scope
  }

  parseGender(g: string) {
    return parseGender(g)
  }

  detailClick(val: AnyObj) {
    if (val.id) {
      this.curPerson = val
      this.showDetailDialog = true
    }
  }
  async searchImg(val: string) {
    let param = {}
    this.rectImage = null
    this.imageError = null
    this.searchImageData = val
    if (val.indexOf('base64,') > -1) {
      param = {
        imageData: val.split(',')[1]
      }
    } else {
      param = {
        imageUrl: val
      }
    }
    this.loading = true
    this.tabLoading = true
    this.resultList = []
    this.personInfo = {}
    this.isResult = false
    this.noMore = false
    this.isLoad = false
    this.index = 0
    await this.getPersonDetect(val)
    await this.getSimilarPerson(param)
  }
  async getPersonDetect(val: string) {
    const resp = await detect.getPersonDetect(val, true)
    if (resp) {
      if (resp.error) {
        this.imageError = resp.error
      } else {
        this.rectImage = resp.image
      }
    }
  }

  async getSimilarPerson(param: AnyObj) {
    const { data } = await this.$api.base.getPersonsSimilar(Object.assign(param, { topN: 100 }))
    if (data.data && data.data.length > 0) {
      const resp = data.data
      const person = resp.shift()
      this.personList = resp
      const opt = {
        params: {
          scopeId: this.root
        }
      }
      if (person.id) {
        this.personInfo = person
        const resp1 = (await this.$api.base.getPersonInfo(person.id)).data
        const resp2 = (await this.$api.base.mostFrequencyArea(person.id, opt)).data
        const res = await Promise.all([resp1, resp2])
        const mostFrequencyArea = res[1].data && res[1].data.name ? res[1].data.name : null
        const { firstAccessTime, frequency, latestAccessTime, times } = res[0].data
        Object.assign(this.personInfo, {
          firstAccessTime,
          frequency,
          latestAccessTime,
          times,
          mostFrequencyArea
        })
        this.isResult = true
      } else {
        this.personInfo = {}
        this.isResult = false
      }
      this.getMoreResult()
      this.listTitle = '近似目标'
      this.isSimilar = true
    }
    this.tabLoading = false
    this.loading = false
  }
  async getResultList() {
    this.$message.closeAll()

    const params = {
      limit: 20,
      offset: this.offset
    }
    let resp
    try {
      resp = await this.$api.base.getAllPersonsList(params)
      this.isLoad = true
      if (resp.data && resp.data.code === 0) {
        this.finished = true
        if (this.offset + 20 >= resp.data.count) {
          this.isLoad = false
          if (this.offset !== 0) {
            this.noMore = true
          }
        }
        this.resultList = [...this.resultList, ...resp.data.data]
      }
    } finally {
      this.tabLoading = false
      this.moreLoading = false
    }
  }

  boxScroll() {
    const box = document.querySelector('.list-item-box')
    if (!box) return false
    const m = box.scrollHeight - box.scrollTop - box.clientHeight
    if (m > -2 && m < 2 && box.scrollTop !== 0 && this.finished) {
      this.finished = false
      if (this.isLoad) {
        this.moreLoading = true
        setTimeout(() => {
          if (this.isSimilar) {
            //查询相似的人
            this.getMoreResult()
          } else {
            //查询人员
            this.offset = this.offset + 20
            this.getResultList()
          }
        }, 1000)
      }
    }
  }

  getMoreResult() {
    this.isLoad = true
    const count = Math.ceil(this.personList.length / 20)
    if (this.index < count - 1) {
      this.resultList = this.resultList.concat(
        this.personList.slice(this.index * 20, this.index * 20 + 20)
      )
      this.index++
    } else {
      const list = this.personList.slice(this.index * 20, this.personList.length)
      this.resultList = this.resultList.concat(list)
      if (this.index === count - 1) {
        this.isLoad = false
        if (this.index !== 0) {
          this.noMore = true
        }
      }
    }
    this.finished = true
    this.moreLoading = false
  }
}
